@font-face {
  font-family: "marilatte";
  src: url(font/scs/marilatte.script.otf);
}

@font-face {
  font-family: "BeneScriptine";
  src: url(font/scs/BeneScriptineRegular.ttf);
}

.active {
  color: red;
}
